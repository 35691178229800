import {tools} from "@/js/tools.js";
import {lazyHandler} from './lazy/lazyHandler';
import {Map} from "@/js/ahoy/Map.js";
import moment from 'moment';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import {TweenMax, TimelineMax} from "gsap"; // Also works with TweenLite and TimelineLite
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import {
    cookieBannerEnabled, cookiebotExists,
    cookieConsentFallback,
    handlePendingScripts,
    marketingCookiesEnabled,
    notEUAndNotChanged
} from './CookiebotHandler';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
import {sukoaUtils} from './sukoaUtils';
import Splide from "@splidejs/splide";
import Collapsible from "@/js/collapsible/Collapsible.js";
import Cookie from "@/js/Cookie.js";


const calendar = {
    init: function () {
        calendar.bind();
    },
    bind: function () {
        function getOpenNowString() {
            let component = document.getElementById('open-now-content');

            if (component) {
                let open = component.getAttribute("data-open");
                let close = component.getAttribute("data-close");
                let from = component.getAttribute("data-from");
                let until = component.getAttribute("data-until");
                let since = component.getAttribute("data-since");
                let dailyOpeningHours = component.getAttribute("data-daily-open");
                let dailyClosingHours = component.getAttribute("data-daily-close");
                let openTime = component.getAttribute("data-open-time");
                let closeTime = component.getAttribute("data-close-time");

                let today = moment(); //Get the current date
                let todayHoursString = today.format("HH:mm");

                let todayH = moment(todayHoursString, "HH:mm");
                let openH = moment(openTime, "HH:mm");
                let closeH = moment(closeTime, "HH:mm");

                if (openH > closeH) {
                    closeH.add(1, 'days');
                }

                let openDiff = openH.diff(todayH);
                let closeDiff = closeH.diff(todayH);

                if (openDiff > 0) {
                    return open + " " + from + " " + dailyOpeningHours;
                } else if (openDiff <= 0 && closeDiff >= 0) {
                    return open + " " + until + " " + dailyClosingHours;
                } else if (closeDiff < 0) {
                    return close + " " + since + " " + dailyClosingHours;
                } else {
                    return close;
                }
            }
        }

        if (document.getElementById('open-now-content')) {
            document.getElementById('open-now-content').innerHTML = getOpenNowString();
            window.setInterval(function () {
                document.getElementById('open-now-content').innerHTML = getOpenNowString();
            }, 120000);
        }
    }
};

// desktop/mobile: checks the given datestart and dateend data-attributes and hides accordingly on client side
function checkDates(elements) {
    // hide entries
    if (elements.length > 0) {
        [...elements].forEach(div => {

            function isHideEntry(startdate, enddate) {

                // this is a strange conditional. One to one from java method. Returns different values based on whether or not enddate is set.
                let n = new Date().getTime() - (24 * 60 * 60 * 1000);

                if (typeof enddate == "undefined") {
                    enddate = startdate;
                }

                return (enddate * 1 < n * 1);
            }

            let divId = div.getAttribute('id');
            let divMobile = document.getElementById('#' + divId + "-mobile");
            let enddate = div.dataset.enddate;
            let startdate = div.dataset.startdate;
            let hide = isHideEntry(startdate, enddate);

            if (hide) {
                if (sukoaUtils.isEditMode()) {
                    // append info to div in editmode
                    if (!document.querySelector('.admin-feedback')) {
                        let newChild = document.createElement('div')
                        let refChild = div.firstElementChild;
                        newChild.class = 'admin-feedback';
                        newChild.innerHTML = '<span class="error">ACHTUNG: Eintrag wird aufgrund des Datums nicht mehr angezeigt.</span>';
                        div.insertBefore(newChild, refChild);
                    }
                } else {
                    // hide entry in public view
                    toggleDisplay(div)
                    toggleDisplay(divMobile)
                }
            }
        });
        let last = elements[elements.length - 1];
        let lastId = last.getAttribute('id');
        let lastMobile = document.querySelector("#" + lastId + "-mobile");
        if (lastMobile) lastMobile.classList.add('no-mobile-border');
    }
}

// cache killer for link element
function cacheKillerLink() {
    [...document.querySelectorAll(".kclink")].forEach(function (el) {
        let l = el.getAttribute("href");
        if (l !== undefined) {
            if (l.indexOf("?") === -1) {
                l += "?";
            } else {
                l += "&";
            }
            l += "kc=" + new Date().getTime();
            el.setAttribute("href", l)
        }
    });
}

// cache killer for src (img, script ...) element
function cacheKillerImg() {
    [...document.querySelectorAll(".kcsrc")].forEach(function (el) {
        let l = el.getAttribute("src");
        if (l !== undefined) {
            if (l.indexOf("?") === -1) {
                l += "?";
            } else {
                l += "&";
            }
            l += "kc=" + new Date().getTime();
            el.setAttribute("src", l)
        }
    });
}


const navigation = {
    subnavOpenInit: false,

    init: function () {
        navigation.bind();
        navigation.openSubNav();
        navigation.openArchiveSubNav();
        navigation.topNavActiveToggle();
    },
    bind: function () {
        let body = document.querySelector('body');
        let burgerMenuClasses = ['dark-yellow', 'tinguely-pink', 'tinguely-blue'];

        let r = -1;
        let changeNavColor = function () {
            body.classList.remove(burgerMenuClasses[r]);
            r = (r + 1) % burgerMenuClasses.length;
            body.classList.add(burgerMenuClasses[r]);
        };

        // fix resize bug
        const burger = document.querySelector(".c-hamburger");
        if (burger){
            burger.addEventListener('click', (e) => {
                e.preventDefault();
                let t = burger;

                (t.classList.contains("is-active") === true) ? t.classList.remove("is-active") : t.classList.add("is-active");

                if (t.classList.contains("is-active")) {

                    body.classList.add("noScroll");
                    changeNavColor();

                    document.getElementById('navigation-wrapper').classList.add("menu-open");
                    let htmlString = document.querySelectorAll('footer .links').innerHTML;
                    let footerLinks = document.getElementById('footerLinks');
                    if (footerLinks) {
                        footerLinks.innverHTML = htmlString;
                    }

                    // add padding with height of 'message-forward' element if it is visible (edit mode)
                    let navigation = document.getElementById('navigation');
                    let banner = document.getElementById('message-forward');
                    let defaultPadding = 100;
                    let addPadding = 0;

                    if (isMobile()) {
                        defaultPadding = 0;
                    }
                    if (banner) {
                        addPadding += banner.offsetHeight;
                    }

                    // if cookie-banner is visible, add height to padding
                    if (body.classList.contains('show-cookie-alert') && !isMobile()) {
                        let cookieBanner = document.getElementById('cookieAlert');
                        if (cookieBanner){
                            let cookieBannerHeight = cookieBanner.offsetHeight;
                            console.log(cookieBannerHeight)
                            addPadding += cookieBannerHeight;
                        }
                    }

                    navigation.style.marginTop = (addPadding + defaultPadding).toString() + "px";

                } else {
                    body.classList.remove("noScroll");
                    document.getElementById('navigation-wrapper').classList.remove("menu-open");
                }

                let closeWrap = document.querySelector('.menu-open .close-menu-wrapper');
                if (closeWrap) {
                    closeWrap.addEventListener('click', function () {
                        t.classList.remove("is-active");

                        body.classList.remove("noScroll");
                        body.classList.remove(burgerMenuClasses[r]);
                        document.getElementById('navigation-wrapper').classList.remove("menu-open");
                    });

                }
                if (!navigation.subnavOpenInit) {
                    // add eventListener to all open buttons
                    setTimeout(function () {
                        let navGroup = document.querySelectorAll('.nav-main-level > ul > li');
                        let openNavLi = document.querySelector('.nav-main-level > ul > li.subnav-open, .nav-main-level > ul > li.active');

                        if (openNavLi) {
                            openNavLi.subnavopen = openNavLi.classList.contains('subnav-open');
                            let targetTopPos = openNavLi.offsetTop;

                            for (let j = 2; j < navGroup.length; j++) {
                                if (!openNavLi.subnavopen || !navGroup[j].isEqualNode(openNavLi)) {
                                    if (Math.abs(navGroup[j].offsetTop - targetTopPos) < 20) {
                                        navGroup[j].classList.add('subnav-open');
                                        let iconOpenBtn = navGroup[j].querySelector('.open-icon');
                                        if (iconOpenBtn) {
                                            iconOpenBtn.classList.add('open-icon-open');
                                        }
                                    }
                                }
                            }
                        }
                    }, 100);
                    navigation.subnavOpenInit = true;
                }
            });
        }

        function resizeHeaderPadding() {
            // if cookie-banner is visible, add height to padding
            if (body.classList.contains('show-cookie-alert') && !isMobile()) {
                let cookieBannerHeight = document.getElementById('cookieAlert').offsetHeight;
                document.querySelector('header').style.paddingTop = cookieBannerHeight+ 'px';
            } else {
                document.querySelector('header').style.paddingTop = '0px';
            }

            let height = outerHeight(document.querySelector('header'));
            document.getElementById('main-wrapper').style.paddingTop = height + 10 + 'px';
        }

        function resizeHeaderWrapperHeight() {
            let headerWrapper = document.getElementById('header-wrapper');
            let headerContent = document.getElementById('header-content');
            let headerContentHeight = outerHeight(headerContent)
            headerWrapper.style.height = (headerContentHeight + 5) + 'px';
        }

        function resizeFooterHeight() {
            let fHeight = document.querySelector('footer').offsetHeight;
            document.querySelectorAll('main#main-wrapper, #home-links-area .slide-container').forEach(elt =>{
                elt.style.paddingBottom = fHeight + 'px';
            });
        }

        resizeHeaderWrapperHeight();
        resizeHeaderPadding();
        resizeFooterHeight();
        setTimeout(function () {
            resizeHeaderWrapperHeight();
            resizeHeaderPadding();
            resizeFooterHeight();
        }, 400);

        window.addEventListener("resize", function () {
            resizeHeaderPadding();
            resizeFooterHeight();
        });
    },

    topNavActiveToggle: function () {
        let navTop = document.querySelector(".nav-info-top-wrapper");
        if (navTop){
            let topLinks = navTop.getElementsByTagName("a");
            let currentUrl = window.location.href;

            for (let i = 0; i < topLinks.length; i++) {
                if (topLinks[i].href === currentUrl) {
                    topLinks[i].classList.add('active');
                } else {
                    topLinks[i].classList.remove('active');
                }
            }
        }
    },

    openSubNav: function () {
        let elements = document.getElementsByClassName('open-icon');
        let navGroup = document.querySelectorAll('.nav-main-level > ul > li');

        // start at i=2 to display first two rows "Ausstellungen" & "Veranstaltungen"
        //todo: can i remove the class in Freemarker?
        if (navGroup.length > 0) {
            for (let i = 0; i < 2; i++) {
                if (navGroup[i]) {
                    navGroup[i].classList.add('subnav-open-info');
                    let r = navGroup[i].querySelector('.open-icon');
                    if (r) {
                        r.parentNode.removeChild(r);
                    }
                }
            }
        }


        if (elements) {
            // when subnavigation is opened
            let openLevelTwoNavigation = function (e) {
                const target = e.target;
                target.classList.toggle('open-icon-open');
                target.parentElement.classList.toggle('subnav-open');

                const targetTopPos = target.getBoundingClientRect().top;
                for (let j = 0; j < elements.length; j++) {
                    if (!elements[j].isEqualNode(target)) {
                        if (Math.abs(elements[j].getBoundingClientRect().top - targetTopPos) < 20) {
                            elements[j].parentNode.classList.toggle('subnav-open');
                            elements[j].classList.toggle('open-icon-open');
                        }
                    }
                }
                //todo: check position in row and open all other elements in the row too
            };

            // add eventListener to all open buttons
            for (let i = 0; i < elements.length; i++) {
                elements[i].addEventListener('click', openLevelTwoNavigation, false);
            }
        }


    },

    openArchiveSubNav: function () {
        let archiveSubNav = document.getElementById("subnavigation");
        let openArchiveNav = function () {
            archiveSubNav.classList.toggle('subnav-archive-open');
        }

        if (archiveSubNav) {
            let openIcon = archiveSubNav.querySelectorAll('.archive-subnav-icon')[0];
            openIcon.addEventListener('click', openArchiveNav, false);
        }
    }

};


const collection = {
    init: function () {
        collection.filters();
    },
    filters: function () {
        let collectionFilter = document.getElementById('collection_filter');
        if (collectionFilter) {
            let dateStartFilterBaseValue = document.querySelector("#collection_filter select#start_date").value;
            let dateEndFilterBaseValue = document.querySelector("#collection_filter select#end_date").value;
            let materialFilterBaseValue = document.querySelector("#collection_filter select#material").value;
            let resetFilter = document.querySelector("#collection_filter input#reset");
            let dateStartFilter = "";
            let dateEndFilter = "";
            let materialFilter = "";
            let params = '';

            collectionFilter.querySelectorAll('select').forEach(select => {
                select.addEventListener('change', function () {
                    changeListener();
                });
            })

            function changeListener() {
                dateStartFilter = document.querySelector("#collection_filter select#start_date").value;
                dateEndFilter = document.querySelector("#collection_filter select#end_date").value;
                materialFilter = document.querySelector("#collection_filter select#material").value;
                params = "";

                if (dateStartFilterBaseValue !== dateStartFilter || dateEndFilterBaseValue !== dateEndFilter) {
                    if (dateEndFilter !== "" && dateStartFilter !== "") {
                        updateParams();
                    } else if (dateEndFilter === "" && dateStartFilter === "") {
                        updateParams();
                    }
                } else {
                    updateParams();
                }

                function updateParams() {
                    if (dateEndFilter !== "" && dateStartFilter !== "") {
                        if (dateStartFilter <= dateEndFilter) {
                            params += "period=" + dateStartFilter + "-" + dateEndFilter;
                        } else {
                            params += "period=" + dateStartFilter + "-" + dateStartFilter;
                        }

                        if (materialFilter !== "") {
                            params += "&";
                        }
                    }

                    if (materialFilter !== "") {
                        params += "material=" + materialFilter;
                    }


                    if (params !== "") {
                        params = "?" + params;
                    }

                    let url = sukoaUtils.getCurrentURL(true) + params;
                    window.location.replace(url);
                }
            }

            resetFilter.addEventListener('click', function () {
                let url = sukoaUtils.getCurrentURL(true);
                window.location.replace(url);
            });
        }
    },
    mobile() {
        // mobile navigation functionality
        // var selected = $('#${mobileNavId} #selected');
        // var selectedOriginalContent = selected;
        // var dropdown = $('#${mobileNavId} .dropdown-menu');
        // var current = $('#${mobileNavId} .dropdown-menu .active').text();
        //
        // if (current == "") {
        //     current = "${mobileLabel}";
        // }
        //
        // selected.html(current);
        // dropdown.hide();
        // selected.click(function(){
        //     dropdown.slideToggle();
        // });
        //
        // $('#${content.@uuid} .dropdown-menu a').click(function(){
        //     selected.html($(this).text());
        //     dropdown.hide();
        // });
    }
};


const collapsible = {
    init: function () {
        const collapsibleElts = document.querySelectorAll('.collapsible-wrapper');

        const initCollapsibleFn = wrapper => {
            const btns = wrapper.querySelectorAll('a.expand-action');
            const collapsibleElt = wrapper.querySelector('.expand-content');
            const collapsibleArea = wrapper.querySelector('.expand-wrap');

            if(btns && collapsibleElt && collapsibleArea) {
                new Collapsible('', wrapper, btns, collapsibleElt, collapsibleArea);
            }
        }

        tools.each(collapsibleElts, initCollapsibleFn)

        document.querySelectorAll('img.svg').forEach(img => {
            let imgID = img.getAttribute('id');
            let imgClass = img.getAttribute('class');
            let imgURL = img.getAttribute('src');

            fetch(imgURL)
                .then(response => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then(data => {
                    let svg = data.firstElementChild
                    if (svg && svg.tagName.toLowerCase() === 'svg') {
                        // Add replaced image's ID to the new SVG
                        if (typeof imgID !== 'undefined') {
                            svg.setAttribute('id', imgID);
                        }
                        // Add replaced image's classes to the new SVG
                        if (typeof imgClass !== 'undefined') {
                            svg.setAttribute('class', imgClass + ' replaced-svg');
                        }

                        // Remove any invalid XML tags as per http://validator.w3.org
                        svg.removeAttribute('xmlns:a');

                        // Replace image with new SVG
                        img.parentNode.replaceChild(svg, img);
                    }
                }).catch(error => {
                // Handle error
                console.error(error)
            });

        });
    }
};


const chapterDivider = {
    init: function () {
        chapterDivider.bind();
    },
    bind: function () {
        document.querySelectorAll('.chapter-divider').forEach(chapDiv => {
            chapDiv.querySelectorAll('.chapter-divider-container').forEach(chapCont => {
                let chapterHeight = chapCont.offsetHeight;

                // init controller
                let controller = new ScrollMagic.Controller();

                // build tween
                let title = chapCont.querySelectorAll(".chapter-title");
                let tween1 = TweenMax.from(title, 0.5, {
                    opacity: 0,
                    transform: 'translateY(150px)'
                });

                let zoomWindow = chapCont.querySelector(".zoom-window");
                let tween2 = TweenMax.from(zoomWindow, 0.5, {scale: 2});

                let parallaxWindow = chapCont.querySelector(".parallax-window");
                let tween3 = TweenMax.from(parallaxWindow, 0.5, {backgroundPosition: 'bottom'});

                // build scene
                let scene1 = new ScrollMagic.Scene({
                    triggerElement: "#" + chapCont.getAttribute('id') + "",
                    offset: chapterHeight / 2
                })
                    .setTween(tween1);
                let scene2 = new ScrollMagic.Scene({
                    triggerElement: "#" + chapCont.getAttribute('id') + "",
                    duration: chapterHeight
                })
                    .setTween(tween2);
                let scene3 = new ScrollMagic.Scene({
                    triggerElement: "#" + chapCont.getAttribute('id') + "",
                    duration: chapterHeight
                })
                    .setTween(tween3);


                controller.addScene([
                    scene1,
                    scene2,
                    scene3
                ]);
            });
        });
    }
};


const quote = {
    init: function () {
        quote.bind();
    },
    bind: function () {
        document.querySelectorAll('.contententry.quote').forEach(quoteEl => {
            quoteEl.querySelectorAll('.quote-content').forEach(quoteContent => {
                let quoteHeight = quoteContent.height;

                // init controller
                let controller = new ScrollMagic.Controller();

                let quoteLeft = quoteContent.querySelector(".quote-left");
                let tween1 = TweenMax.from(quoteLeft, 0.5, {
                    opacity: 0,
                    transform: 'translateX(-150px)'
                });
                let quoteRight = quoteContent.querySelector(".quote-right");
                let tween2 = TweenMax.from(quoteRight, 0.5, {
                    opacity: 0,
                    transform: 'translateX(150px)'
                });

                // build scene
                let scene1 = new ScrollMagic.Scene({
                    triggerElement: "#" + quoteContent.getAttribute('id') + "",
                    offset: "-350px"
                })
                    .setTween(tween1);
                let scene2 = new ScrollMagic.Scene({
                    triggerElement: "#" + quoteContent.getAttribute('id') + "",
                    offset: "-350px"
                })
                    .setTween(tween2);

                controller.addScene([
                    scene1,
                    scene2
                ]);
            });
        });
    }
};


//TODO: MIGPENDING slick needs jqueryless replacement
const sliderapp = {
    init: function () {
        sliderapp.bind();
        sliderapp.initHomeSlider();
    },
    bind: function () {
        let idt;

        window.addEventListener("resize", function () {
            if (idt) clearTimeout(idt);
            idt = setTimeout(isTablet, 100);
        });

        tools.each(document.querySelectorAll('.minigal.splide'), splideWrap => {
            let s = new Splide(splideWrap, {
                type: 'slide',
                pagination: false,
                autoWidth: true,
                gap: '24px',
                lazyLoad: 'sequential'
            });
            s.mount();
        })
    },
    initHomeSlider: function () {
        tools.each(document.querySelectorAll('.home-slider.splide'), slider => {

            let splide = new Splide(slider, {
                type: 'fade',
                pagination: false,
                arrows: false,
                autoplay: 'play',
                pauseOnHover: false,
                easing: 'ease',
                width: '100vw',
                height: '100vh',
                cover: true,
                rewind: true,
                lazyLoad: 'sequential'
            });


            if (document.querySelector('#home')) {
                let slideCurrentId = null;

                splide.on('active', (Slide) => {
                    if (slideCurrentId != null) {
                        let previousText = document.getElementById(slideCurrentId);
                        if (previousText) {
                            previousText.classList.remove('slide-hovered');
                        }
                    }
                    let currentSlide = Slide.slide;
                    slideCurrentId = currentSlide.dataset.uuid;
                    let currentText = document.getElementById(slideCurrentId);
                    if (currentText) {
                        currentText.classList.add('slide-hovered');
                    }
                });
            }

            splide.mount();

            if (document.querySelector('#home')) {
                // on hover over slider text, show all text and display the corresponding slider image
                const Autoplay = splide.Components.Autoplay;
                let isTouchscreen = isTablet();
                const navEntries = document.querySelectorAll('nav.slider-nav > ul > li');
                tools.each(navEntries, navEntry => {
                    let index = [].indexOf.call(navEntries, navEntry);
                    let timeout = null;
                    let ignoreBeforeChange = false;

                    if (!isTouchscreen) {
                        navEntry.addEventListener("mouseenter", () => {
                            if (timeout) {
                                window.clearTimeout(timeout);
                                timeout = null;
                            }
                            // stop slider
                            Autoplay.pause();
                            ignoreBeforeChange = true;

                            timeout = setTimeout(function () {
                                splide.go(index);
                            }, 200);
                        })

                        navEntry.addEventListener('mouseleave', () => {
                            if (timeout) {
                                window.clearTimeout(timeout);
                            }
                            ignoreBeforeChange = false;
                            Autoplay.play();
                        })
                    }
                });
            }
        });
    }
};


const home = {
    linksInit: function () {
        let index = 0;
        let sliderNav = document.querySelector('#home-links-area:not(.edit-mode)');
        if (sliderNav) {
            let linksslideshoautoplayspeed = parseInt(sliderNav.getAttribute("data-linksslideshoautoplayspeed"));
            let linksslideshowdelay = parseInt(sliderNav.getAttribute("data-linksslideshowdelay"));
            let linksFadeSpeed = parseInt(sliderNav.getAttribute("data-linksFadeSpeed"));
        }
    },

    sliderInit: function () {
        if (document.getElementById('home-links-area')) {
            let list = document.querySelectorAll(".slider-nav ul li");
            if (list) {
                let listSize = list.length;

                list.forEach((liEl, index) => {
                    liEl.style.zIndex = listSize;
                    listSize = listSize - 1;
                    const id = liEl.dataset.uuid;
                });
            }
        }
    },

    eventCalendarInit: function () {
        let homeEventList = document.querySelector("#home .home-event-list");
        let listAreaWrapper = homeEventList.querySelector(".events-list-area-wrapper");
        let listArea = homeEventList.querySelector(".events-list-area");

        function hideEventFromHeight() {
            document.querySelectorAll(".event-entry-wrapper.hide").forEach(hidden => {
                hidden.classList.remove("hide");
            });

            while (listArea.offsetHeight > listAreaWrapper.offsetHeight) {
                let entries = listArea.querySelectorAll(".event-entry-wrapper:not(.hide)");
                entries[entries.length - 1].classList.add("hide");
            }
        }

        hideEventFromHeight();

        window.addEventListener("resize", function () {
            hideEventFromHeight();
        });
    }
};


let cookieConsentName = "tinguely-cookie-accepted";
const cookieconsent = {
    init: function () {
        if (!cookieconsent.readCookie(cookieConsentName) && document.getElementById("cookieAlert") != null) {
            document.querySelector('body').classList.add('show-cookie-alert');
        }
    },

    cookieOptIn: function () {
        cookieconsent.createCookie(cookieConsentName, "true", false);

        document.querySelector('body').classList.remove('show-cookie-alert');
        let height = document.querySelector('header').offsetHeight;
        document.querySelector('main#main-wrapper, #home-links-area .slide-container').style.paddingTop = height + 10 + 'px';

        // set header padding-top to 0 after cookie is accepted
        document.querySelector('header').style.paddingTop = 0;
    },

    createCookie: function (name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        ;
        document.cookie = name + "=" + value + expires + "; path=/";
    },

    readCookie: function (name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
};


function languageSelection() {
    if (document.body.classList.contains("home") && !sukoaUtils.isEditMode() && !sukoaUtils.isGoogleBot()) {
        let cookieName = 'tinguely-website-language-selection';
        let targetLang = window.navigator.language;
        targetLang = targetLang.length > 2 ? targetLang.substring(0, 2) : targetLang;
        let currentLang = document.documentElement.lang;
        if (cookieconsent.readCookie(cookieName)) {
            targetLang = cookieconsent.readCookie(cookieName);
        }

        if (targetLang != currentLang) {
            let targetLangLink = document.getElementById("lang_link_" + targetLang.toLowerCase());
            if (targetLangLink) {
                window.location.replace(targetLangLink.href);
            }
        }

        let langNavLinks = document.getElementsByClassName("lang-nav-links");
        Array.prototype.filter.call(langNavLinks, function (langNavLink) {
            langNavLink.addEventListener("click", function () {
                cookieconsent.createCookie(cookieName, langNavLink.getAttribute('title'), 365);
            });
        });
    }
}

const scrollTop = {
    init: function () {
        let goTopElt = document.getElementById('goTop');
        if (goTopElt){
            goTopElt.style.display = 'block';
            window.onscroll = function () {
                const scrolled = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
                if (scrolled > 100) {
                    goTopElt.style.height = '28px';
                } else {
                    goTopElt.style.height = '0px';
                }
            };
            goTopElt.addEventListener('click', function () {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                goTopElt.style.height = '0px';
            });
        }
    }
};

function initAhoyAnimation() {
    // MAP
    sukoaUtils.each(document.getElementsByClassName('map-component-wrapper'), mapComponent => {
        Map.initMap(mapComponent);
    });

    // Event List
    sukoaUtils.each(document.getElementsByClassName('open-detail-text'), detailText => {
        let moreDetail = detailText.getElementsByClassName('more-detail')[0];
        let moreDetailLink = detailText.getElementsByClassName('more-detail-link')[0];
        moreDetailLink.addEventListener('click', e => {
            detailText.classList.toggle('open');
        });
    });
    // Scroll to detail in List
    sukoaUtils.each(document.getElementsByClassName('ahoy-detail-list'), detailList => {
        let selectedItem = detailList.getElementsByClassName('selected-item')[0];
        if (selectedItem) {
            window.scrollTo(0, selectedItem.offsetTop);
            setTimeout(() => {
                window.scrollTo(0, selectedItem.offsetTop);
            }, 300);
        }
    });
}


function outerHeight(el) {
    return el.offsetHeight
}


function toggleDisplay(el) {
    const prev = el.style.display;
    if (prev === 'none') {
        el.style.display = 'block';
    } else {
        el.style.display = 'none';
    }
}

// mobile: show collapsed content
function mobileShowCollapsedContent() {
    const showText = document.querySelector('.show-text');
    if (showText) {
        showText.addEventListener('click', function (e) {
            e.preventDefault();
            let t = e.target.el;
            let p = t.parent();
            let ht = p.querySelector('.hidden-text');
            toggleDisplay(ht);
            toggleDisplay(p.querySelector('.arrow-down'));
            toggleDisplay(p.querySelector('.arrow-up'));
        });
    }
}


function isTablet() {
    return window.innerWidth < 1024;
}

function isMobile() {
    return window.innerWidth < 750;
}

// mail decrypt
window.sukoaUtils = {dcmadr: sukoaUtils.dcmadr};

const app = {
    init() {
        document.documentElement.classList.remove('no-js');
        document.documentElement.classList.add('loaded');

        languageSelection();

        this.lazyness();
        this.cookieBanner();
        this.cookiebotOnAccept();
        this.mediaEmbeds();
        calendar.init();
        navigation.init();
        sliderapp.init();
        calendar.init();
        collection.init();
        collapsible.init();
        chapterDivider.init();
        quote.init();
        scrollTop.init();
        home.linksInit();
        home.sliderInit();
        // hideCTAButtonIfSubNav();
        if (document.getElementById("home")) {
            home.eventCalendarInit();
        }
        this.imageDownloadSwitch();

        // mobile
        mobileShowCollapsedContent();
        isTablet();

        // components
        checkDates(document.querySelectorAll('.textAndImage'));

        //utils
        cacheKillerLink();
        cacheKillerImg();

        // Init AHOY
        initAhoyAnimation();

        //Mobile Nav
        let mobile_nav_icon = document.getElementById('mobile_nav_icon');
        let mobile_sub_nav = document.getElementById('mobile_sub_nav');
        if (mobile_nav_icon && mobile_sub_nav) {
            mobile_nav_icon.addEventListener('click', e => {
                mobile_sub_nav.classList.toggle('open');
                document.body.classList.toggle('no-scroll');
            });
        }
    },

    lazyness() {
        lazyHandler();
    },

    imageDownloadSwitch(){
        tools.each(document.getElementsByClassName('image-downloads'), imageDownload => {
            let switchBtn = imageDownload.querySelector('.switch-btn');
            let listIcon = switchBtn.querySelector('.list-icon');
            let gridIcon = switchBtn.querySelector('.grid-icon');
            listIcon.addEventListener('click', e => {
                imageDownload.classList.add('list');
                imageDownload.classList.remove('grid');
            })
            gridIcon.addEventListener('click', e => {
                imageDownload.classList.add('grid');
                imageDownload.classList.remove('list');
            })
        })
    },

    cookieBanner() {
        if(cookieBannerEnabled()){
            if(cookiebotExists() && !Cookiebot.isOutOfRegion) {
                // inside Cookiebot region Cookiebot will handle the rest, we only need to hide the basic banner
                cookieconsent.cookieOptIn();
            } else {
                // show basic cookie banner on top if not EU (or set region) and
                // set all cookiebot consents to true (so they can be adjusted through footer settings by user)
                cookieconsent.init();
                Cookiebot.submitCustomConsent(true, true, true);
                Cookiebot.hide();
            }
        } else {
            if(cookiebotExists()) {
                Cookiebot.hide();
            }
        }
        document.body.classList.remove('temp-hide-cookiebanner')

        handlePendingScripts();
    },

    cookiebotOnAccept() {
        window.addEventListener('CookiebotOnConsentReady', () => {
            document.querySelectorAll('.cookie-consent-required').forEach(elt => {
                if(elt.defRes) {
                    elt.defRes(true);
                }
                elt.remove();
            });
        })
    },

    mediaEmbeds(){
        const videoWrappers = document.querySelectorAll('.type-vimeoEmbed, .type-soundcloudEmbed, .type-youtubeEmbed');

        const wrapperInitFn = wrapper => {
            if (marketingCookiesEnabled()) {
                console.log("marketing enabled")
            } else {
                const iframeWrapper = wrapper.querySelector('iframe');
                const translatedCategories = iframeWrapper.dataset.cookieconsent.split(',').map(s => window['cookieconsentCategory'+s] || s).join(',')
                wrapper.dataset.cookieconsent = translatedCategories
                cookieConsentFallback(wrapper).then(() => btn.click()).catch();
            }
        }

        tools.each(videoWrappers, wrapperInitFn)
    }
}

window.cookieconsent = {cookieOptIn: cookieconsent.cookieOptIn};
document.addEventListener('DOMContentLoaded', () => {
    app.init();
});
