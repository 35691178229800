// import Overlay from "./Overlay";

export function cookiebotExists() {
    try {
        if(Cookiebot) {
            return true;
        }
    } catch(e) {
        window.Cookiebot = false;
        document.querySelectorAll('a[href*="javascript:Cookiebot"]').forEach(a => a.remove());
    }
    return false;
}

export function marketingCookiesEnabled() {
    return cookiebotExists() && Cookiebot.consent !== undefined && Cookiebot.consent.marketing;
}

export function statisticsCookiesEnabled() {
    return cookiebotExists() && Cookiebot.consent !== undefined && Cookiebot.consent.statistics;
}

export function consentForCookieCategory(cookiesCategory = '') {
    if(cookiesCategory === 'marketing') return marketingCookiesEnabled();
    else if(cookiesCategory === 'statistics') return statisticsCookiesEnabled();
    else return false;
}

export function consentForCookieCategories(cookiesCategories = []) {
    return cookiesCategories.reduce((a,c) => {
        return a && consentForCookieCategory(c);
    }, true);
}

export function isCookieConsentConform(wrapperElt = new HTMLElement()) {
    let result = true;
    if(wrapperElt.dataset.cookieconsent) {
        result = consentForCookieCategories(
            wrapperElt.dataset.cookieconsent.split(',')
                .reduce((a,c) => {
                    a.push(c.trim());
                    return a;
                }, [])
        )
    }

    return result;
}

export function cookieConsentFallback(wrapperElt) {

    let defRes, defRej;
    const cookieConsentPromise = new Promise((res, rej) => {
        defRes = res;
        defRej = rej;
    });

    const div = buildCCRequiredElt(wrapperElt.dataset.cookieconsentTitle || null, wrapperElt.dataset.cookieconsentText || null, wrapperElt.dataset.cookieconsent.split(','), wrapperElt.dataset.thirdpartyUrl || null)
    wrapperElt.style.position = 'relative';
    const wrapperBoundingRect = wrapperElt.getBoundingClientRect();
    const insertedDiv = wrapperElt.insertAdjacentElement('beforeend', div);
    insertedDiv.defRes = defRes;
    insertedDiv.defRej = defRej;

    return cookieConsentPromise;
}

export function handlePendingScripts() {
    if(window.waitForCookieConsent) window.waitForCookieConsent.forEach(handlePendingScript);
}

function handlePendingScript(e) {
    const cookieConsentCategories = e.cookieconsentCategories;

    if(!cookieConsentCategories || cookieConsentCategories.length === 0 || consentForCookieCategories(cookieConsentCategories)) {
        const elt = e.wrapperElt;
        e.wrapperElt.innerHTML = e.innerHTML.replaceAll('#s#c#r#i#p#t#', 'script');

        elt.querySelectorAll('script').forEach(s => {
            const script = document.createElement('script');

            for (let attr of s.attributes) {
                if (attr.name !== 'src') {
                    script[attr.name] = attr.value;
                }
            }

            script.innerHTML = s.innerHTML;
            if(s.src) {
                script.src = s.src;
            }

            s.remove();
            elt.appendChild(script)
        })
        e.done = true;
        return null;

    } else {
        let defRes, defRej;
        const cookieConsentPromise = new Promise((res, rej) => {
            defRes = res;
            defRej = rej;
        });
        const wrapperElt = e.wrapperElt;
        wrapperElt.style.position = 'relative';

        const {cookieconsentTitle, cookieconsentText, cookieconsentCategories, cookieconsentThirdparty} = e;

        const div = buildCCRequiredElt(cookieconsentTitle, cookieconsentText, cookieconsentCategories, cookieconsentThirdparty, e.showCookieconsent);
        div.classList.add('static');

        const insertedDiv = wrapperElt.insertAdjacentElement('beforeend', div);
        insertedDiv.defRes = defRes;
        insertedDiv.defRej = defRej;

        cookieConsentPromise.then(() => handlePendingScript(e));
        return cookieConsentPromise;
    }
}

function buildCCRequiredElt(contextCCTitle, contextCCText, contextCCCategories = [], contextCCThirdpartyUrl, showCookieconsent = true) {
    const elt = document.createElement('div')
    elt.classList.add('cookie-consent-required');

    if(showCookieconsent) {
        let localCookieConsentText = contextCCText || cookieconsentText;
        if(localCookieConsentText.indexOf('##cookieCategories##') && contextCCCategories && contextCCCategories.length > 0) {
            localCookieConsentText = localCookieConsentText.replace('##cookieCategories##', contextCCCategories.filter(s => !consentForCookieCategory(s)).map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(', '));
        }

        let innerHTML = '<h2>' + (contextCCTitle || cookieconsentTitle) + '</h2>' +
            '<p>'+ localCookieConsentText +'</p>';

        if(cookiebotExists()) {
            innerHTML += '<p><button onclick="Cookiebot.renew()">' + cookieconsentButton + '</button></p>';
        }

        if(cookieconsentThirdparty && contextCCThirdpartyUrl) {
            const thirdPartyText = cookieconsentThirdparty;

            if(thirdPartyText.indexOf('##website##') !== -1) {
                const link = '<a target="_blank" href="'+contextCCThirdpartyUrl+ '">' + new URL(contextCCThirdpartyUrl).hostname+ '</a>';
                innerHTML += '<p>' + cookieconsentThirdparty.replace('##website##', link) +'</p>';

            } else {
                innerHTML += '<p><a target="_blank" href="'+contextCCThirdpartyUrl+ '">' + cookieconsentThirdparty + '</a>.</p>';
            }
        }
        elt.innerHTML = innerHTML;
    } else {
        elt.style.display = 'none';
    }
    return elt;
}

export function notEUAndNotChanged() {
    return Cookiebot.isOutOfRegion && !Cookiebot.changed;
}

export function cookieBannerEnabled() {
    return document.body.classList.contains("show-cookie-banner");
}
